.navSideBar {
    display: flex;
    flex-direction: column;
    padding: 0px 0;
    background: #FFFFFF;
    box-shadow: 0px -3px 18px -5px rgba(0, 0, 0, 0.25);
    height: auto;
    z-index: 2;
    min-height: 100vh;
    position: fixed;
    
    @media screen and (max-width: 426px) {
        display: none;
    }

    .buttonLink {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        font-size: 13px;
        gap: 10px;
        margin-bottom: 5px;
    }
    .buttonLink:hover, .active {
        background-color: #F1F1F1;
    }

    .buttonLink, .buttonLink:visited {
        Color: black;
    }
    
    .fontRobotoRegular {
        font-family: PromptLight;
        font-size: 13px;
    }
}

.textMenu {
    text-align: center;
    margin: 20px 0;
}

.moduleSelected {
    text-align: center;
    border-bottom: 3px solid black;
    padding: 0 0 10px 0;
    
    h3 {
        margin: 5px 0;
    }

    p {
        font-size: 13px;
    }
}