.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.groupButtonDestop {
    padding: 10px 0 10px 20px;
    @media screen and (max-width: 426px) {
        display: none;
    }
}

.cardItems {
    display: flex;
    overflow-x: scroll;
    padding: 10px 0 10px 20px;
    gap: 10px;

    @media screen and (max-width: 426px) {
        display: none;
    }
    .buttonCardItem {
        border: none;
        border-radius: 15px;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        background-color: white;

        padding: 30px 20px;
    }
    .active {
        background-color: #F1F1F1;
    }
}



.selectFilter {
    @media screen and (min-width: 426px) {
        display: none;
    }
}