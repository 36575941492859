.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.pageWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.skuImage{
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    
    @media screen and (min-width: 426px) {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
    }   
}

.skuGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    // margin: 15px 0px;
}

.stockReportContainer{
    .topLabelContainer {
        display:flex;
        justify-content: space-around;
    }

    .bottomLabelContainer{
        display:flex;
        justify-content: space-around;
    }
}

.lotListContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;   
}

.groupInput {
    display: flex;
    flex-direction: row;
    gap: 5px;
    
    .colInput {
        display: flex;
        flex-direction: column;
        width: calc(50% - 2.5px);
    }
}

.sectionItem {
    margin-top: 10px;
    @media screen and (min-width: 426px) {
        display: flex;
        gap: 20px;
    }
}

.rowHeadTable {
    background-color: white;
    text-align: center;
    border-bottom: 1px solid #F1F1F1;
}

.rowBodyTable {
    background-color: white;
    text-align: center;
    font-size: 16px;
}

.groupButtonDestop {
    padding: 10px 0 10px 20px;
    @media screen and (max-width: 426px) {
        display: none;
    }
}
