// START ===> RESET TAG CSS
// table, caption, tbody, tfoot, thead, tr, th, td {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
//     background-color: white;
// }
// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }
// END ===> RESET TAG CSS



body {
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.margin5 {
    margin: 3px;
    font-size: 11px;
}

.page {
    width: 210mm;
    height: 267mm;
    padding: 10mm;
    margin: 15mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    // border: 2px black solid;
    height: 247mm;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.newRow {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
}

.row {
    display: flex;
    flex-direction: row;
    
    .col9 {
        display: flex;
        flex-direction: row;
        flex-basis: 70%;
        // justify-content: center;
        // align-items: center;
        // border-bottom: 1px black solid;
        // border-right: 1px black solid;
        z-index: 0;

        .logo {
            display: flex;
            flex-direction: column;
            margin: 5px 10px 5px 5px;
            justify-content: center;
            align-items: center;
        }

        .myAddressCompony {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        
        .infoCustomer {
            display: flex;
            flex-direction: column;
        }
    }

    .col3 {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        justify-content: center;
        align-items: center;
        // border-bottom: 1px black solid;
        z-index: 2;

        .groupNoAndDate {
            display: flex;
            flex-direction: row;
            width: -webkit-fill-available;
            // border-top: 1px black solid;

            .groupNo, .groupDate {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 50%;

                .subGroupNo {
                    width: -webkit-fill-available;
                    text-align: center;
                    // border-bottom: 1px black solid;
                }
            }
            .groupDate {
                // border-left: 1px black solid;
                height: 57px;
            }
        }

        .groupDocumentNo {
            display: flex;
            flex-direction: column;
            width: -webkit-fill-available;
            margin-left: 5px;
        }
    }
}

.tablePDF {
    width: -webkit-fill-available;
    border: none;
    margin: 0;
    // z-index: -1;

    thead {
        th {
            background-color: white;
            border: 0.5px solid black;
            // border-bottom: 0.5px solid black;
            // border-right: 0.5px solid black;
            text-align: center;
            padding: 10px 4px;
            font-size: 11px;
        }
    }

    tbody {
        td {
            border: 0.5px solid black;
            // border-bottom: 0.5px solid black;
            // border-right: 0.5px solid black;
            font-size: 11px;
            text-align: center;
            height: 35px;
        }
    }

}