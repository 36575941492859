.section1 {
  margin: 10px 0 0 0;
  @media screen and (min-width: 426px) {
      margin: 35px 0 0 40px;
  }
}

.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}

.subHeaderTitle {
  @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}

.widthTable {
  max-width: 100px;
  min-width: 100px; 
  width: 100px;
}