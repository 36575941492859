.section1 {
    margin: 90px 0 0 0;
    @media screen and (min-width: 426px) {
        margin: 35px 0 0 40px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}
.subHeaderTitle {
    @media screen and (max-width: 436px) {
        margin: 10px 0 0 20px;
      }
}
.menuItemsWrapper {
    display: flex;
    margin: 25px 0px 0 0;

    justify-content: start;
    
    gap: 25px;
    
    @media screen and (max-width: 320px) {
        gap: 15px;
    }

    @media screen and (max-width: 436px) {
        // justify-content: center;
        margin-left: 20px;
    }

    button {
        display: flex;
        width: 150px;
        height: 150px;
        padding: 10px 10px;
        margin: 3px 0px;

        border-radius: 20px;
        border: none;

        background: #F1F1F1;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);

        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        
        @media screen and (max-width: 320px) {
            width: 140px;
            height: 140px;
        }

    }
}



.newCol {
    min-width: 209px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tag {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    min-height: 54px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 5px;

    padding: 10px 20px;
}

.grayTag {
    background-color: #F1F1F1;
}
.orangeTag {
    background-color: #F49D36;
}
.purpleTag {
    background-color: #7E73FF;
}
.yellowTag {
    background-color: #FCFF5B;
}
.greenTag {
    background-color: #4CAF50;
}
.redTag {
    background-color: #F44336;
}

.tagCard {
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;

    gap: 5px;

    padding: 10px 20px;

    p {
        font-size: 11px;
        font-weight: bold;
    }

    .timeStamp {
        color: #BEBEBE;
        font-size: 11px;
        text-align: end;
        margin-top: 10px;
    }
}

.tagCard:hover {
    background: #F1F1F1;
}

.borderGreen {
    border-left: 4px solid #4CAF50;
}
.borderOrange {
    border-left: 4px solid #F49136;
}