.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.tableList {
    overflow-x: scroll;
    margin-bottom: 10px;
}

.groupButton {
    padding: 20px 0 10px 20px;
}

.tagRed, .tagGreen {
    border-radius: 5px;
    background-color: #F44336;

    padding: 5px 8px;

    width: fit-content;
    color: white;
}

.tagGreen {
    background-color: #4CAF50;
}