.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle, .headerTitleBody {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}

.marginTop10 {
    margin-top: 10px;
}

.row {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;

    @media screen and (max-width: 768px) {
        flex-basis: 45%;
    }
    @media screen and (max-width: 426px) {
        flex-basis: 100%;
    }
}

.styleTextarea {
    border: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin: 5px 0;
    padding: 10px 15px;
    border-radius: 15px;
    width: -webkit-fill-available;
}