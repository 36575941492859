.headerWrapper, .cards, .bodyWrapper{
    padding: 0px 20px;
}

.cards{
    margin: 10px 0;
    display: flex;
    overflow-x: scroll;
    gap: 10px;

    .card {
        border-radius: 20px;
        background-color: #EEEEEE;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        min-width: 150px;
        cursor: pointer;
        
        .statusWaiting, .statusDone {
            font-size: 10px;
            font-weight: bold;
            color: #F49136;
        }
        .statusDone {
            color: #4CAF50;
        }
    }

    .active {
        background-color: black;

        h4, p {
            color: white;
        }
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}


.bodyWrapper {
    margin-bottom: 20px;
}

input[type=file] {
    display: none;
}

.buttonUploadFile {
    background-color: black;
    color: white;
    padding: 10px 15px;
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    width: fit-content;
}

.groupImages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .groupImage {

        img {
            @media screen and (max-width: 320px) {
                width: 135px;
            }
            @media screen and (min-width: 321px) {
                width: 160px;
            }
            @media screen and (min-width: 424px) {
                width: 187px;
            }
        }
    }
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 10px;
}



.linkTogoogleMap {
    background-color: black;
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
    height: 40px;
    margin: auto 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
}