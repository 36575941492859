.loginWrapper {
  display:flex;
  flex-direction: column;
  margin: 75px auto;
  max-width: 300px;
  // border: 5px solid red;

  @media screen and (min-width: 375px){  // width >= 375
      max-width: 350px;
  }
  @media screen and (min-width: 425px){  // width >= 425
      max-width: 405px;
  }
}

.titleHeader {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.buttons {
  text-align: center;
}

.buttonForgot {
  background-color: white;
  color: black;
  font-weight: bold;
}