.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.tableList {
    overflow-x: scroll;
    margin-bottom: 60px;
}

.groupButtonDestop {
    padding: 10px 0 10px 20px;
    @media screen and (max-width: 426px) {
        display: none;
    }
}

.cardItems {
    display: flex;
    overflow-x: scroll;
    padding: 10px 0 10px 20px;
    gap: 10px;

    @media screen and (max-width: 426px) {
        display: none;
    }
    .buttonCardItem {
        border: none;
        border-radius: 15px;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        background-color: white;

        padding: 30px 20px;
    }
    .active {
        background-color: #F1F1F1;
    }
}



.sectionItem {
    margin-top: 10px;
    padding: 0px 20px;
    @media screen and (min-width: 426px) {
        display: flex;
        gap: 20px;
    }
}

.skuImage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 426px) {
        width: 250px;
        height: 220px;
        background: #FFFFFF;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
    } 

    .buttonUploadFile {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        background-color:white;
        color: black;
        padding: 8px 10px;
        font-size: 12px;
        font-weight: bold;
        margin: 10px auto 0 0px;
        display: flex;
        width: fit-content;
        @media screen and (min-width: 426px) {
            margin: 10px auto 0 30px;
        } 
    }  
}

.groupInput {
    display: flex;
    flex-direction: row;
    gap: 5px;
    
    .colInput {
        display: flex;
        flex-direction: column;
        width: calc(50% - 2.5px);
    }
}