// pagination.module.scss
// @import 'src/styles/colours.scss';
// Colour variables used: $primary, $white
.paginationWrapper {
  padding: 0px 20px 10px;
  display: flex;
  justify-content: left;
}
.separator {
  width: 1rem;
  margin: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageItem {
  background: transparent;
  border: none;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.25rem;
  border-radius: 5px;
  font-weight: 600;
  color: black;
&:hover {
    text-decoration: underline;
  }
&:focus {
    outline: 0;
  }
}
.active {
  background-color: #E5E5E5;
  color: black;
}
.sides {
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
&:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
}