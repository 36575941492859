.section1 {
    margin: 90px 0 0 0;
    @media screen and (min-width: 426px) {
        margin: 35px 0 0 40px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
      display: none;
    }
}
.headerTitle2 {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}
.subHeaderTitle {
    @media screen and (max-width: 436px) {
        margin: 10px 0 0 20px;
        display: none;
      }
}
.menuItemsWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 10px 0px 0 0;

    justify-content: start;
    
    gap: 25px;

    @media screen and (max-width: 320px) {
        gap: 15px;
    }

    @media screen and (max-width: 436px) {
        justify-content: center;
    }

    button {
        display: flex;
        width: 150px;
        height: 150px;
        padding: 10px 10px;
        margin: 3px 0px;

        border-radius: 20px;
        border: none;

        background: #F1F1F1;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);

        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        
        @media screen and (max-width: 320px) {
            width: 140px;
            height: 140px;
        }

        .fontRobotoRegular {
            font-family: PromptLight;
            font-weight: bold;
        }
    }
}

.shortCutDesktop {

    .menuItemsWrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 10px 0px 0 0;
    
        justify-content: start;
        
        gap: 25px;
    
        @media screen and (max-width: 320px) {
            gap: 15px;
        }
    
        @media screen and (max-width: 436px) {
            justify-content: center;
        }
    
        button {
            display: flex;
            width: 150px;
            height: 150px;
            padding: 10px 10px;
            margin: 3px 0px;
    
            border-radius: 20px;
            border: none;
    
            background: #F1F1F1;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            
            @media screen and (max-width: 320px) {
                width: 140px;
                height: 140px;
            }
    
            .fontRobotoRegular {
                font-family: PromptLight;
                font-weight: bold;
            }
        }
    }
    
    @media screen and (max-width: 425px) {
       display: none;
    }


}

.shortCutMobile {
    display: none;
    @media screen and (max-width: 425px) {
       display: block;

       .menuItemsWrapper {
            display: flex;
            flex-wrap: unset;
            margin: 10px 20px;
        
            justify-content: start;
            align-items: flex-start;
            overflow-x: scroll;
            
            button {
                width: 100px;
                height: 30px;
                border-radius: 5px;

                display: flex;
                flex-direction: row;

                img {
                    width: 20px;
                    height: 20px;
                }
                span {
                    font-size: 12px;
                }
            }
        }
    }
}