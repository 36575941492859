.navBar {
    padding: 10px 0 0 100px;
    // padding: 10px 0 0 20px;
    box-shadow: 0px -3px 18px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1;

    @media screen and (max-width: 426px) {
        padding: 10px 0 0 10px;
      }
}

.logo {
    width: 130px;
}


.title {
    margin: auto;
    @media screen and (min-width: 426px) {
        display: none;
      }
}


.group {
    margin: 0 10px 0 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    @media screen and (min-width: 426px) {
        margin: 0 10px 0 auto;
      }
}

.profile {
    display: flex;
    align-items: center;
}

.logout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background-color: #F1F1F1;
    border-radius: 54px;
    height: 30px;
    width: 125px;

    @media screen and (max-width: 426px) {
        display: none;
      }
}