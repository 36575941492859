.headerWrapper {
  padding: 60px 20px 0px;
  
  @media screen and (min-width: 426px) {
    padding: 0px 20px;
}
}

.headerTitle {
  font-size: 25px;
  margin-bottom: 0;
}

.rowWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rowNoWrap {
  display: flex;
  flex-direction: row;
}

.colWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //   background-color: rgb(0, 255, 64);
}

.colAddress {
  display: flex;
  flex-direction: row;
}

.groupInput {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 5px;
  width: 33%;
}
.groupInputAddress {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 5px;
  width: 50%;
}

.groupInputOnly {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 5px;
  width: 50%;
}

.groupInputRow {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  //   border-style: solid;
  //   border-color: rgb(255, 0, 0);
  //   gap: 5px;
  //   width: 100px;
}

.groupInputRowRight {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  justify-content: flex-end;
  //   border-style: solid;
  //   border-color: rgb(255, 0, 0);
  width: 500px;

  .textTitle {
    h4 {
      margin: 18px 20px 18px 0;
    }
  }
}

.sectionHalf {
  //   width: calc((30% - 2px));
  width: 50%;

  //   border-style: solid;
  //   border-color: rgb(255, 0, 0);
  //   background-color: rgb(0, 255, 64);
}

textarea {
  max-width: 1000px;
  display: flex;
  padding: 0 10px;
}

.styleTextarea {
  max-width: 1000px;
  // display: flex;
  // padding: 0 10px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 5px 0;
  padding: 10px 15px;
  border-radius: 15px;
  width: -webkit-fill-available;
}

@media only screen and (max-width: 800px) {
  .groupInput {
    width: 160px;
    // background-color: rgb(238, 255, 0);
  }
}

@media only screen and (max-width: 600px) {
  .sectionHalf {
    width: 100%;
    // background-color: rgb(255, 8, 0);
  }

  .groupInput {
    width: 50%;
  }

  .colAddress {
    display: flex;
    flex-direction: column;
  }

  .groupInputRowRight {
    justify-content: flex-start;
    width: 100%;
  }
  .groupInputOnly {
    width: 80%;
  }
}

.tableList {
  overflow-x: scroll;
}