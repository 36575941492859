@import './normalize.scss'; /* bring in normalize.css styles */

/* This is Recommended reset from CRA from https://create-react-app.dev/docs/adding-css-reset */

*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: PromptLight;
    // font-weight: 100;
}

@font-face{
    font-family: PromptLight;
    src: url("./fonts/Prompt-Light.ttf")
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

.summaryTopTableStyle {
  @media screen and (min-width: 426px) {
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
}

.wapperBody {
  margin: 100px 0 0 80px;
  @media screen and (max-width: 426px) {
    margin: 0px
  }
}

// Dividers
.divider-10 {
    display: block;
    height: 10px;
}
.divider-20 {
    display: block;
    height: 20px;
}

.inputForSearch {
    height: 40px;

    border: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin: 5px 0;
    padding: 10px 15px;
    border-radius: 15px;
    width: -webkit-fill-available;
    -webkit-appearance: none;
}

.styleBtn {
    // width: 80px;
    // height: 40px;
    
    background-color: black;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 10px 15px;
    margin: 8px 5px
}

.inputOriginal {
  border: none;
  // box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  // margin: 3px 0;
  padding: 5px;
  // border-radius: 10px;
  width: 80px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    // padding: 10px 7px;
    // margin: 8px 5px;

    margin: 0px 3px;
    -webkit-appearance: none;
}

.styleBtnForTable {
  // width: 52px;
  // height: 26px;
  padding: 5px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
}

.uniqueSKU {
  background-color: black;
  color: white;
  padding: 5px;
}
.statusScanerGreen, .statusScanerRed, .statusScanerOrange {
  font-size: 9px;
  padding: 2px;
  border-radius: 5px;
  color: white;
  background-color: #4CAF50;
}
.statusScanerRed {
  background-color: #F44336;
}
.statusScanerOrange {
  background-color: #F49136;
}

.errMsg{
  position: absolute;
  color: red;
  font-size: 11px;
}

a:hover,
a:active,
a:focus,
input:hover,
input:active,
input:focus,
button:hover,
button:active,
button:focus {
  outline: 0;
}

textarea {
  resize: none;
  -webkit-appearance: none;
}

.groupButtonFooter {
  bottom: 0px;
  right: 0px;
  margin: 16px 10px;
  position: fixed;
  @media screen and (min-width: 426px) {
    position: relative;
  }
}

button:disabled {
  opacity: 50%;
  cursor: no-drop;
}


table {
  th, td {
    span{
      font-family: PromptLight;
    }
  }
}

.borderLeftRadius {
  border-top-left-radius: 15px;
}
.borderRightRadius {
  border-top-right-radius: 15px;
}
.borderBottomLeftRadius {
  border-bottom-left-radius: 15px;
}
.borderBottomRightRadius {
  border-bottom-right-radius: 15px;
}

.table {
  font-family: PromptLight;
  border-collapse: collapse;
  width: -webkit-fill-available;
  background-color: white;
  color: black;
  margin: 10px 0;

  @media screen and (min-width: 426px) {
    margin: 10px 20px;
    width: fit-content;
    min-width: 500px;
  }
  
}

.tr {
  white-space: nowrap;
}
.tr:nth-child(even) {
  background-color: #ececec;
}

.th {
    font-size: 14px;
    padding: 15px 8px;
    width: fit-content;
    background-color: #DADADA;
}

.td {
    font-size: 12px;
    // max-width: 120px;
    min-width: 60px;
    width: fit-content;
    padding: 10px 8px;

    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden;
}
.td, .th {
  text-align: start;
  text-align: left;
}

.tableListMui {
  overflow-x: scroll;
  margin: 0px 10px 10px 20px;
}