.pageWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.tableList {
    overflow-x: scroll;
}

.styleBtnForTable {
    // width: 52px;
    // height: 26px;
    padding: 5px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-weight: bold;
    font-size: 11px;
}
.groupbtn {
    // text-align: end;
    position: fixed;
    right: 10px;
    bottom: 20px;
}