.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle, .headerTitleBody {
    font-size: 25px;
    margin-bottom: 0;
    @media screen and (max-width: 436px) {
      margin: 10px 0 0 20px;
    }
}

.headerTitleBody {
    font-size: 22px;
    margin: 0px;
    text-decoration: underline;
}

.subHeaderTitle {
    @media screen and (max-width: 436px) {
        margin: 10px 0 0 20px;
      }
}

.width100 {
    width: 100%;
}

.hiddenInMpbile {
    @media screen and (max-width: 426px) {
        display: none;
    }
}

.hiddenInDesktop {
    @media screen and (min-width: 426px) {
        display: none;
    }
}

.newRow {
    display: flex;
    gap: 10px;
    
    // @media screen and (max-width: 767px) {
    //    flex-direction: column;
    // }

    input {
        max-width: 300px;
        display: flex;

        @media screen and (max-width: 426px) {
            max-width: 194px;
        }
        @media screen and (max-width: 376px) {
            max-width: 169px;
        }
        @media screen and (max-width: 321px) {
            max-width: 141px;
        }
    }

    select {
        max-width: 300px;
        display: flex;

        @media screen and (max-width: 426px) {
            max-width: 194px;
        }
        @media screen and (max-width: 376px) {
            max-width: 169px;
        }
        @media screen and (max-width: 321px) {
            max-width: 141px;
        }
    }
    textarea {
        max-width: 610px;
        display: flex;
        @media screen and (max-width: 376px) {
            max-width: 346px;
        }
        @media screen and (max-width: 321px) {
            max-width: 291px;
        }
    }

    .styleTextarea {
        border: none;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        margin: 5px 0;
        padding: 10px 15px;
        border-radius: 15px;
        width: -webkit-fill-available;
    }

    .newCol50 {
        display: flex;
        flex-basis: 40%;
        flex-wrap: wrap;
        @media screen and (width: 768px) {
            flex-basis: 90%;
        }
    }
}

.editStyleBtn, .styleBtnGreen, .styleBtnRed {
    background-color: black;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 5px 0;
    padding: 6px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.styleBtnGreen {
    background-color: #4CAF50;
}
.styleBtnRed {
    background-color: #F44336;
}

.styleBtnProgress {
    width: fit-content;
    max-width: 500px;
    min-width: 150px;
    height: 50px;
    padding: 0 30px 0 20px;

    border: none;
    background: #F1F1F1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    text-align: start;

    img {
        margin: 0 10px 0 0px;
    }
}

.styleBtnProgressShip {
    margin: 0 0 0 -30px;
}

.statusNewCustomer, .statusPass, .statusFarmimg, .statusQuotation, .statusClose, .statusCannotClose {
    max-width: 60px;
    height: 14px;
    border-radius: 5px;
    border: none;
    padding: 2px 3px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.statusNewCustomer {
    background-color: #F1F1F1;
}

.statusPass {
    background-color: #F49D36;
}

.statusFarmimg {
    background-color: #7E73FF;
}

.statusQuotation {
    background-color: #FCFF5B;
}

.statusClose {
    background-color: #4CAF50;
}

.statusCannotClose {
    background-color: #F44336;
}

.textAlignCenter {
    text-align: center;
}

.statusBlack, .statusOrange, .statusRed {
    width: 43px;
    height: 14px;
    border-radius: 5px;
    border: none;
    padding: 2px 3px;
    font-size: 10px;
    font-weight: bold;
    color: white;
}

.statusRed {
    background-color: #F44336;
}

.statusOrange {
    background-color: #F49136;
}

.statusBlack {
    background-color: black;
}

.grayTag {
    background-color: #F1F1F1;
}
.orangeTag {
    background-color: #F49D36;
}
.purpleTag {
    background-color: #7E73FF;
}
.yellowTag {
    background-color: #FCFF5B;
}
.greenTag {
    background-color: #4CAF50;
}
.redTag {
    background-color: #F44336;
}

.typeDocument {
    font-size: 13px;
    font-weight: bold;
}

.timeStampUser {
    font-size: 10px;
    color: #BEBEBE;
}

.greenDocument {
    width: fit-content;
    font-size: 10px;
    color: white;
    padding: 2px 3px;
    margin: 0;
    background: #4CAF50;
    border-radius: 5px;
}

.documentList {
    flex-direction: column;
    display: flex;
    gap: 5px;
    min-width: 220px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 5px 10px;
}

.documentList:hover {
    background-color: rgb(233, 233, 233);
}

.documentLists {
    max-width: 610px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.tabWrapper{
    margin: 0;
    width: 100%;
    
    button {
        padding: 0 10px 0 0;
        font-size: 16px;
    }
    button:hover {
        background-color: white;
    }
    button::before {
        background-color: white;
    }
}

.specialThisOne {
    @media screen and (max-width: 426px) {
        width: 380px;
    }
    @media screen and (max-width: 376px) {
        width: 340px;
    }
    @media screen and (max-width: 321px) {
        width: 280px;
    }
}

.tabWrapperMobile{
    margin: 0;
    display: none;
    
    @media screen and (max-width: 426px) {
        width: 100%;
        display: block;
    }
    @media screen and (max-width: 376px) {
        width: 87%;
        display: block;
    }
    @media screen and (max-width: 321px) {
        width: 90%;
        display: block;
    }


    button {
        padding: 0 10px 0 0;
        font-size: 16px;
        width: 50%;
        font-weight: bold;
    }

    button {
        padding: 0 10px 0 0;
        font-size: 16px;
        width: 50%;
        font-weight: bold;
        border-radius: 20px;
    }
    button:hover {
        background-color: white;
    }
    button::before {
        background-color: white;
    }

}

.mainWrapper {
    width: 100%;
}   

.groupComment {
    border-left: 5px solid black;

    margin: 20px 0;

    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 1700px;
    gap: 10px;

    .comment {
        width: 100%;
        // height: fit-content;
        // min-height: 80px;
        margin: 0px 10px;
        padding: 0px 0 10px 0;
        border-bottom: 2px solid #EEEEEE;
        .dateName {
            color: #BEBEBE;
            font-size: 10px;
        }
        img {
            margin: 10px 0;
        }
    }
}

.map {
    width: 200px;
    height: 200px;
}

  input[type=file] {
    display: none;
}
.buttonUploadFile {
    background-color: black;
    color: white;
    padding: 8px 4px;
    white-space: nowrap;
    margin: 8px 8px 8px 5px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    // width: fit-content;
}
.refDocument {
    background: #4CAF50;
    border-radius: 5px;

    color: white;

    padding: 2px 5px;
    width: fit-content;

    font-size: 12px;
}

.logComment {
    width: 100%;
}








.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  .pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
  }
  
  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  
  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
  
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }