.headerWrapper{
    padding: 60px 20px 0px;
    @media screen and (min-width: 426px) {
        padding: 0px 20px;
    }
}

.headerTitle {
    font-size: 25px;
    margin-bottom: 0;
}

.tableList {
    overflow-x: scroll;
    margin-bottom: 60px;
}

.toggleView {
    display: flex;
    @media screen and (min-width: 426px) {
        width: 540px;
    }

    .toggleViewBtn {
        margin: 0 20px 0 auto;
    }
}

.feedView {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    margin: 10px 20px;
    overflow-y: scroll;
    height: 400px;
    gap: 10px;
    
    @media screen and (max-width: 376px) {
        margin: 10px 0px;
    }

    .cardInRow {
        display: flex;
        // justify-content: center;

        .card {
            display: flex;
            flex-direction: column;

            justify-content: center;

            border-radius: 20px;
            background-color: #EEEEEE;

            width: -webkit-fill-available;
            max-width: 240px;
            height: 90px;
            padding: 0 20px;

            gap: 3px;

            @media screen and (max-width: 321px) {
                padding: 0 10px;
                height: 110px;
            }

            .cardButton {
                margin: 0px 5px;
                width: 50px;
            }

            h4 {
                font-size: 13px;
            }
            p {
                font-size: 11px;
            }

        }
    }
}
